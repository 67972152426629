<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 text-white border-white bg-transparent" role="alert">
      <div class="flex">
        <div>
          <div class="w-4 h-1 mb-2 bg-black"></div>
          <p class="text-black lg:text-45 text-30 lg:leading-45 font-bold">thank you for your enquiry!</p>
          <p class="text-sm text-black mt-6 mb-48 lg:mb-72 lg:w-2/3">A member of our team will be in touch with you as soon as possible to your audit.</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white bg-transparent p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-black font-axia">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  name="contact-form" :class="{ hidden: success }" class="gap-x-6 formsy gap-y-6 text-left lg:w-10/12 xl:w-8/12" @submit.stop>
      <div>
        <div :class="{ hidden: success }" class="w-4 mb-2 h-1 bg-black"></div>
        <h1 :class="{ hidden: success }" class="text-black lg:text-45 text-30 lg:leading-45 font-bold">get a free website audit</h1>
        <p :class="{ hidden: success }" class="body-text mb-8 mt-4">If you care as much about your business as we do, we’d love to hear from you!</p>
      </div>
      <div class="text-left sm:flex flex-wrap justify-between">
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="name" rules="required" class="mb-4 col-span-1 block sm:mb-0">
          <div>

            <input
                id="form-name"
                placeholder="Name"
                v-model="form.name"
                :class="{ error: errors[0] }"
                type="text"
                class="inputs"
            />
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="Company" rules="required" class="mb-4 col-span-1 block sm:mb-0">
            <div>

              <input
                  id="form-company"
                  placeholder="Company"
                  v-model="form.company"
                  :class="{ error: errors[0] }"
                  type="text"
                  class="inputs"
              />
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="mb-4 block sm:mb-0">
            <div>

              <input
                  id="email"
                  placeholder="Email"
                  v-model="form.email"
                  :class="{ error: errors[0] }"
                  type="text"
                  class="inputs"

              />
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="We" class="mb-4 col-span-1 block sm:mb-0">
            <div>

              <input
                  id="form-url"
                  placeholder="Web Address"
                  v-model="form.url"
                  :class="{ error: errors[0] }"
                  type="text"
                  class="inputs"
              />
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="Phone" rules="required" class="mb-4 col-span-1 block sm:mb-0">
            <div>

              <input
                  id="form-phone"
                  placeholder="Phone"
                  v-model="form.phone"
                  :class="{ error: errors[0] }"
                  type="tel"
                  maxlength="11"
                  class="inputs"
              />
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="Location" class="mb-4 col-span-1 block sm:mb-0">
            <div>

              <input
                  id="form-location"
                  placeholder="Location"
                  v-model="form.location"
                  :class="{ error: errors[0] }"
                  type="text"
                  class="inputs"
              />
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="SiteAge" class="mb-4 block sm:mb-0">
            <div>

              <input type="hidden" v-model="form.siteage" name="SiteAge" />
              <select
                  class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
                  name="SiteAge"
                  aria-label="SiteAge"
                  @change="onChange($event)"
                  v-model="selectvalues"
                  id="form-age"
                  :class="{ error: errors[0] }"
              >
                <option selected value="" disabled>How old is your current site?</option>
                <option value="5+ years">5+ Years Old</option>
                <option value="4 years">4 Years Old</option>
                <option value="3 years">3 Years Old</option>
                <option value="2 years">2 Years Old</option>
                <option value="1 year">1 Year Old</option>
                <option value="Less than a year">Less than a year</option>
              </select>
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49%">
          <validation-provider v-slot="{ errors }" name="budget" class="mb-4 block sm:mb-0">
            <div>

              <input type="hidden" v-model="form.budget" name="budget" />
              <select
                  class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
                  name="budget"
                  aria-label="budget"
                  @change="onChange($event)"
                  v-model="selectvalue"
                  id="form-age"
                  :class="{ error: errors[0] }"
              >
                <option selected value="" disabled>Budget Estimate</option>
                <option value="20k+">£20,000 +</option>
                <option value="15-20k">£15,000 - £20,000</option>
                <option value="10-15k">£10,000 - £15,000</option>
                <option value="5-10k">£5,000 - £10,000</option>
                <option value="1.5-5k">£1,500 - £5,000</option>
                <option value="0-1.5k">0 - £1,500</option>
              </select>
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="sm:w-49% mt-4">
          <p>Do you currently have web support?*</p>
          <div class="mt-4 sm:flex sm:gap-x-4 ">
            <validation-provider v-slot="{ errors }" name="support">
              <div>
                <p class="flex gap-x-1 text-black font-bold">
                  <label class="check-box" style="padding-left: 20px;">
                    <input
                        id="form-support"
                        name="support"
                        placeholder="yes"
                        v-model="form.support"
                        :class="{ error: errors[0] }"
                        type="radio"
                        value="Yes"
                        class="checkbox"
                    >
                    <span class="checkmark"></span>
                  </label>
                  Yes
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="support">
              <div>
                <p class="flex gap-x-1 text-black font-bold">
                  <label class="check-box" style="padding-left: 20px;">
                    <input
                        id="form-support"
                        placeholder="no"
                        name="support"
                        v-model="form.support"
                        :class="{ error: errors[0] }"
                        type="radio"
                        value="No"
                        class="checkbox"
                    >
                    <span class="checkmark"></span>
                  </label>
                  No
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="support">
              <div>
                <p class="flex gap-x-1 text-black font-bold">
                  <label class="check-box" style="padding-left: 20px;">
                    <input
                        id="form-support"
                        placeholder="unsure"
                        name="support"
                        v-model="form.support"
                        :class="{ error: errors[0] }"
                        type="radio"
                        value="Unsure"
                        class="checkbox"
                    >
                    <span class="checkmark"></span>
                  </label>
                  I don't know
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="sm:w-49% mt-4">
          <p>Interested in*</p>
          <div class="mt-4 md:flex md:flex-wrap ">
            <validation-provider v-slot="{ errors }" name="newsite" class="sm:w-1/2">
              <div>
                <p class="flex gap-x-2 text-black font-bold">
                  <label class="check-box">
                    <input
                        id="form-newsite"
                        placeholder="newsite"
                        v-model="form.newsite"
                        :class="{ error: errors[0] }"
                        type="checkbox"
                        class="checkbox"

                    >
                    <span class="checkmark"></span>
                  </label>
                  New Website
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="siterefresh" class="sm:w-1/2">
              <div>
                <p class="flex gap-x-2 text-black font-bold">
                  <label class="check-box">
                    <input
                        id="form-siterefresh"
                        placeholder="siterefresh"
                        v-model="form.siterefresh"
                        :class="{ error: errors[0] }"
                        type="checkbox"
                        class="checkbox"

                    >
                    <span class="checkmark"></span>
                  </label>
                  Website Refresh
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="newlogo" class="sm:w-1/2">
              <div>
                <p class="flex gap-x-2 text-black font-bold">
                  <label class="check-box">
                    <input
                        id="form-newlogo"
                        placeholder="newlogo"
                        v-model="form.newlogo"
                        :class="{ error: errors[0] }"
                        type="checkbox"
                        class="checkbox"

                    >
                    <span class="checkmark"></span>
                  </label>
                  New Logo
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="analytics" class="sm:w-1/2">
              <div>
                <p class="flex gap-x-2 text-black font-bold">
                  <label class="check-box">
                    <input
                        id="form-analytics"
                        placeholder="analytics"
                        v-model="form.analytics"
                        :class="{ error: errors[0] }"
                        type="checkbox"
                        class="checkbox"

                    >
                    <span class="checkmark"></span>
                  </label>
                  Monthly Analytics
                </p>
                <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="sm:w-full mt-6">
          <button  type="button" :disabled="invalid" class="learn-more lg:hidden" @click="passes(SendMessage);reposition();formh();">
                  <span class="circle" aria-hidden="true">
                  </span>
            <span class="button-text flex gap-x-4">send enquiry <img width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg" alt="arrow"></span>
          </button>
          <button  type="button" :disabled="invalid" class="learn-more hover:cursor-pointer hidden lg:inline-block" @click="passes(SendMessage);">
                  <span class="circle" aria-hidden="true">
                  </span>
            <span class="button-text flex gap-x-4">send enquiry <img width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg" alt="arrow"></span>
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      selectvalue: '',
      selectvalues: '',
      success: false,
      error: false,
      form: {
        budget: this.selectvalue,
        siteage: this.selectvalues,
      }
    }
  },
  methods: {

    reposition () {
      this.isOpen = true
      document.querySelector('.repos').classList.toggle('h-small')
    },
    formh () {
      this.isOpen = true
      document.querySelector('.formsy').classList.toggle('hidden')
    },
    onChange(event) {
      this.form.budget = this.selectvalue,
      this.form.siteage = this.selectvalues
    },
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
          .then((response) => {
            this.form = {} // Clear input fields.
            this.$nextTick(() => this.$refs.form.reset())
            this.success = response.data.message
            this.error = false
            /*
            this.$notify({
              type: 'primary',
              showMethod: 'fade in right',
              message: response.data.message
            })*/
          })
          .catch((err) => {
            this.success = false
            this.error = err.response.data.message
            /*
            this.$notify({
              message: err.response.data.message
            })
            */
          })
          .then(() => {
            this.loading = false
          })
    }
  }
}
</script>
<style>
input:active{
  border:none!important;
}
input:focus-visible{
  outline-width: 0px;
}
textarea:focus-visible{
  outline-width: 0px;
}
</style>

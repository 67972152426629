<template>
  <div>
    <div id="nav" class="relative z-30">
      <div class="w-screen overflow-hidden relative z-50 px-8">
        <div class="container py-4 lg:py-8 relative flex justify-between items-center ">
          <div class="relative mb-0">
            <slot name="brand" />
          </div>
          <div class="h-6">
            <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;logoc();addopd();" class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
      <div :class="{ desktopmenu: isActive }"  class="overflow-hidden relative main-show w-screen bg-no-repeat bg-cover block bg-black menu-aligner">
        <div class="lg:py-40 h-full padder w-screen flex md:items-center block">
          <div class="container">
            <div class="flex mx-auto justify-center" style="max-width: 400px;">
              <div class="sm:grid w-full grid-cols-2 gap-x-8 pt-4">
                <div>
                  <slot name="mainmenu" />
                </div>
                <div class="text-white flex sm:order-first justify-center sm:justify-start items-center pb-12">
                  <div>
                    <p class="text-primary  text-xl mb-4 mt-8 sm:mt-0 font-semibold">Services</p>
                    <div class="text-lg text-center sm:text-left space-y-4">
                      <a href="/services/brand/" class="block hover:text-primary transition-all ease-in-out duration-300">brand</a>
                      <a href="/services/web/" class="block hover:text-primary transition-all ease-in-out duration-300">web</a>
                      <a href="/services/3d-cgi/" class="block hover:text-primary transition-all ease-in-out duration-300">cgi/3d</a>
                      <a href="/services/social/" class="block hover:text-primary transition-all ease-in-out duration-300">social</a>
                    </div>
                  </div>
                </div>
                <div class="flex sm:hidden justify-center gap-x-6 mt-4 mb-6">
                  <a aria-label="facebook social Icon" href="https://www.facebook.com/weareiconcreative/" class="social-link"><icon-facebook class="h-6"></icon-facebook></a>
                  <a aria-label="Linkedin social Icon" href="https://www.linkedin.com/company/icon-creative" class="social-link"><icon-linkedin class="h-6"></icon-linkedin></a>
                  <a aria-label="Instagram social Icon" href="https://www.instagram.com/icon.creative.studio/" class="social-link"><icon-instagram class="h-6"></icon-instagram></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addom () {
      document.querySelector('.desktopmenu').classList.toggle('offscreen')
    },
    logoc () {
      document.querySelector('.brand').classList.toggle('whitel')
    },
    addomt () {
      document.querySelector('.moverin').classList.toggle('onscreen')
    },
    addopd () {
      document.querySelector('.positioner-content').classList.toggle('position-down')
    },
    removeo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  left: -101vw!important;
}
.invisi{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-left: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-left: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-left: 105vw;
  }
}
</style>
